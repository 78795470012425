/* layout.css */

.header {
  -webkit-text-size-adjust: 100%;
  font-family: "Quicksand", 'Poppins', Roboto, 'Poppins', sans-serif;
  line-height: 1.4;
  color: #000;
  -webkit-font-smoothing: antialiased;
  font-size: 1.125rem;
  font-weight: 300;
  box-sizing: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  position: relative;
  padding-top: 16px;
  padding-right: 20px;
  margin-bottom: 0;
  border-bottom: .1px solid #dfdfdf;
}

.ul {
  -webkit-text-size-adjust: 100%;
  font-family: "Quicksand",'Poppins',Roboto,'Poppins',sans-serif;
  color: #000;
  -webkit-font-smoothing: antialiased;
  font-size: 1.125rem;
  font-weight: 300;
  box-sizing: inherit;
  line-height: 1.4;
  padding: 0;
  list-style: none outside;
  margin: 0;
  align-items: center;
  display: flex;
  position: relative;
  padding: 0px;
}

.footer-section ul {
  padding-left: 0; /* This will override the browser default padding */
}

.li {
  -webkit-text-size-adjust: 100%;
  font-family: "Quicksand",'Poppins',Roboto,'Poppins',sans-serif;
  color: #000;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  line-height: 1.4;
  box-sizing: inherit;
  font-size: inherit;
  margin: 0;
  list-style: none;
  padding: 0 24px 0 0;
}

.button-sign-in, .button-get-started {
  -webkit-text-size-adjust: 100%;
  font-family: "Quicksand", 'Poppins', Roboto, 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  line-height: 1;
  box-sizing: inherit;
  font-size: 16px;
  margin: 0;
  list-style: none;
  cursor: pointer;
  width: 100%;
  display: inline-block;
  text-align: center;
  appearance: none;
  border: 1px solid #AF85CD;
  background: #fff;
  border-radius: 4px;
  padding: 13px 12px 16px; 
  text-decoration: none;
  text-transform: none;
}
.blue-button-sign-in, .blue-button-get-started {
  -webkit-text-size-adjust: 100%;
  font-family: "Quicksand", 'Poppins', Roboto, 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  line-height: 1;
  box-sizing: inherit;
  font-size: 16px;
  margin: 0;
  list-style: none;
  cursor: pointer;
  width: 100%;
  display: inline-block;
  text-align: center;
  appearance: none;
  border: 1px solid #0874f9;
  background: #fff;
  border-radius: 4px;
  padding: 13px 12px 16px; 
  text-decoration: none;
  text-transform: none;
}

.button-sign-in {
  color: #AF85CD;
}

.button-get-started {
  color: #fff;
  background: #AF85CD;
}

.blue-button-sign-in {
  color: #0874f9;
}

.blue-button-get-started {
  color: #fff;
  background: #0874f9;
}

.button-margin {
  margin-right: 20px; 
}

/* Hover effects for Sign In button */
.button-sign-in:hover {
  background-color: #AF85CD;
  color: #fff;
  border-color: #fff;
}

/* Hover effects for Get Started button */
.button-get-started:hover {
  background-color: #fff;
  color: #AF85CD;
  border-color: #AF85CD;
}
.blue-button-sign-in:hover {
  background-color: #0874f9;
  color: #fff;
  border-color: #fff;
}

/* Hover effects for Get Started button */
.blue-button-get-started:hover {
  background-color: #0c18f7;
}

/* Hover effect for logo */
/* footer */


/* New Footer styles */
.footer {
  font-family: "Quicksand",'Poppins',Roboto,'Poppins',sans-serif;
  background: white;
  display: flex;
  padding: 24px 80px;
  gap: 88px;
  border-top: 1px solid #e5e5e5;
  margin-top: 0px;
}

.footer-list {
  margin-left: 0px;
}

.footer-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.left-footer-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}
.social-footer-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 0px;
}

.footer-logo-text {
  font-size: 2rem;  /* Adjust as necessary */
  font-weight: bold;
}

.footer-copyright {
  font-size: 1rem;
  margin-top: 8px;
  color: #000;
  margin-top: 20px;
}

.section-title {
  font-size: 1rem;
  color: #0874f9; /* Replace with the exact shade of violet you mentioned */
  margin-bottom: 8px;
}
.footer-section li {
  font-size: .8rem;
  list-style: none;
  margin-bottom: 3px;
}

/* ... existing styles ... */

.social-footer-section .social-icons-row {
  display: flex;
  gap: 15px; /* Adjust the spacing between icons if necessary */
  justify-content: flex-start;
  margin-bottom: 10px;  /* Space between rows of icons */
}

.social-footer-section img {
  width: 24px;  /* Adjust icon width */
  height: 24px; /* Adjust icon height */
}

/* footer mobile styles */

/* ... existing styles ... */

@media only screen and (max-width: 768px) {
  .footer {
    padding: 16px; /* reduce padding */
    flex-direction: column; /* stack sections vertically */
    align-items: center; /* center align content */
    gap: 16px; /* reduce gap */
  }
  
  .footer-section, .left-footer-section, .social-footer-section {
    align-items: center; /* center align content */
  }
  
  .footer-logo-text {
    font-size: 1.6rem; /* reduce font-size */
  }
  
  .footer-copyright, .section-title, .footer-section li {
    font-size: .8rem; /* reduce font-size */
  }
  
  .social-footer-section .social-icons-row {
    justify-content: center; /* center align icons */
  }
}

@media only screen and (max-width: 576px) {
  .footer {
    padding: 12px; /* further reduce padding */
    gap: 12px; /* further reduce gap */
  }
  
  .footer-logo-text {
    font-size: 1.4rem; /* further reduce font-size */
  }
  
  .footer-copyright, .section-title, .footer-section li {
    font-size: .7rem; /* further reduce font-size */
  }
}


.basic-container {
  background-color: #d6d6d6;
}

/* zillow copy */

.header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* This keeps the logo section and auth section on opposite ends */
  background-color: #fff;
  padding: 10px 20px; /* Adjusted padding for better spacing */
}

.zillowCopy {
  font-family: Quicksand;
}

.logo-section {
  display: flex;
  align-items: center; /* Aligns the logo and text vertically */
}

.logo-text {
  font-size: 32px; /* Set the size of your logo text here */
  margin-left: 10px; /* Adds some space between the logo image and the text */
  font-family: Artifika;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
  color: #0074e4;
  text-decoration: none;
}

.logo-text-link {
  text-decoration: none;
}

.nav-section, .auth-section {
  display: flex;
  align-items: center;
}

.nav-button {
  background: none;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  margin: 0 10px; /* Adjust spacing between buttons */
}

.nav-button.sign-up {
  border: 2px solid #0074e4; /* Optional border for the sign-up button */
  padding: 8px 18px; /* Adjust padding to keep button size consistent with border */
}

.logo {
  height: 60px; /* Increased size of the logo */
  margin: 0 10px; /* Space around the logo */
}

/* ... rest of your CSS */
.zillowCopy .search-section {
  width: 100%;
  /* Remove the height if it's set here to avoid conflicts with the inline style */
  display: flex;
  align-items: center;
  justify-content: center;
  /* The background properties are being set inline, so they can be removed from here */
}

/* ... rest of your CSS */

.zillowCopy .search-title {
  color: #ffffff; /* Make the title white */
  font-size: 36px; /* Large font size */
  margin-bottom: 20px; /* Space above the search bar */
  padding: 0 20px; /* Padding for small screens */
  max-width: 80%; /* Maximum width to maintain readability */
  text-shadow: 0 3px 6px rgba(0, 0, 0, 1); /* Text shadow for better readability */
  font-weight: bolder;
}

.zillowCopy .search-container {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0; /* Remove padding if you want the input and button to be snug */
  border-radius: 5px; /* Rounded edges */
  display: flex;
  max-width: 700px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative; /* Needed for absolute positioning of the pseudo-element */
}

.zillowCopy .search-container:focus-within {
  outline: 3px solid #2e8de5; /* Your custom focus outline style */
  outline-offset: 0px; /* Adjust the offset as needed */
}
.zillowCopy .search-container2 {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0; /* Remove padding if you want the input and button to be snug */
  border-radius: 5px; /* Rounded edges */
  display: flex;
  max-width: 700px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  position: relative; /* Needed for absolute positioning of the pseudo-element */
  color: #000;
  font-weight: 500;
}

.zillowCopy .search-container2:focus-within {
  outline: 3px solid #2e8de5; /* Your custom focus outline style */
  outline-offset: 0px; /* Adjust the offset as needed */
}

.zillowCopy .search-input {
  border: none;
  padding: 24px 0px 24px 16px;
  font-size: 18px;
  flex: 1; /* Take up available space */
  border-radius: 5px 0 0 5px; /* Rounded left side */
  margin-right: -1px; /* Align button seamlessly */
  font-family: Quicksand;
  font-weight: 500;
  color: #000;
}
.zillowCopy .search-input:focus {
  /* Remove the default input focus outline */
  outline: none;
}

.zillowCopy .search-input:focus {
  /* Remove the default input focus outline */
  outline: none;
}


.zillowCopy .search-button {
  background-color: white; /* Zillow blue or the color you prefer */
  color: #0074e4;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  border-radius: 0 5px 5px 0; /* Rounded right side */
}

.zillowCopy .search-button .FaSearch {
  display: block;
}
.zillowCopy .search-button .FaSearch:hover {
  cursor: pointer;
}

@media (aspect-ratio: 3 / 1) {
  .zillowCopy .search-section {
    background-size: auto 100%; /* Adjust the size so the height is covered and width is auto scaled */
  }
}

.zillowCopy .search-container .suggestions-bubble {
  position: absolute;
  top: 100%; /* Positions the bubble right below the input field */
  left: 0;
  width: 98%; /* Ensures the bubble stretches across the whole container */
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 8px;
  display: flex;
  flex-direction: column;
  z-index: 10; /* Ensure it's above other content */
  margin-top: 0.5em; /* Adjust as needed to provide space between the input box and the bubble */
  align-items: flex-start; /* Aligns suggestions to the left */
}

.zillowCopy .search-container .suggestion {
  background: none; /* Removes button background */
  border: none; /* Removes button border */
  outline: none; /* Removes focus outline */
  padding: 8px 16px;
  border-radius: 8px;
  margin: 4px 0;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: left;
  font-family: inherit; /* Ensures the button font matches other elements */
  font-size: inherit; /* Inherits the font size from parent element */
  color: inherit; /* Inherits the text color from parent element */
  line-height: inherit; /* Inherits the line height from parent element */
  display: block; /* Makes the button behave like a block-level element */
  width: 100%; /* Ensures the button takes up the full width of its container */
  text-align: left; /* Ensures text is left-aligned */
}

.zillowCopy .search-container .suggestion:hover {
  background-color: #f2f2f2;
}

.zillowCopy .search-container .suggestion.message {
  background: transparent; /* Ensures the message button has no background */
  pointer-events: none; /* Disables pointer events like hover, click on the message */
  font-style: italic;
  font-weight: bold;
  color: #6f6f6f;
}


.zillowCopy .search-container .suggestion:not(.message):hover {
  background-color: #f2f2f2; /* Hover effect only for actual suggestions */
}
.zillowCopy .search-container2 .suggestions-bubble {
  position: absolute;
  top: 100%; /* Positions the bubble right below the input field */
  left: 0;
  width: 98%; /* Ensures the bubble stretches across the whole container */
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 8px;
  display: flex;
  flex-direction: column;
  z-index: 50000000; /* Ensure it's above other content */
  margin-top: 0.5em; /* Adjust as needed to provide space between the input box and the bubble */
  align-items: flex-start; /* Aligns suggestions to the left */
}

.zillowCopy .search-container2 .suggestion {
  background: none; /* Removes button background */
  border: none; /* Removes button border */
  outline: none; /* Removes focus outline */
  padding: 8px 16px;
  border-radius: 8px;
  margin: 4px 0;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: left;
  font-family: inherit; /* Ensures the button font matches other elements */
  font-size: inherit; /* Inherits the font size from parent element */
  color: inherit; /* Inherits the text color from parent element */
  line-height: inherit; /* Inherits the line height from parent element */
  display: block; /* Makes the button behave like a block-level element */
  width: 100%; /* Ensures the button takes up the full width of its container */
  text-align: left; /* Ensures text is left-aligned */
}

.zillowCopy .search-container2 .suggestion:hover {
  background-color: #f2f2f2;
}

.zillowCopy .search-container2 .suggestion.message {
  background: transparent; /* Ensures the message button has no background */
  pointer-events: none; /* Disables pointer events like hover, click on the message */
  font-style: italic;
  font-weight: bold;
  color: #6f6f6f;
}


.zillowCopy .search-container2 .suggestion:not(.message):hover {
  background-color: #f2f2f2; /* Hover effect only for actual suggestions */
}

/* Ensure the suggestions bubble does not overlap the search input */
.zillowCopy .search-section {
  position: relative; /* Needed to position the suggestions bubble absolutely */
}


.zillowCopy .trending-homes {
  padding: 20px;
  margin-left: 150px;
  margin-right: 150px;
  overflow: hidden; /* To ensure no overflow due to shadows or margins */
  z-index: 1;
}

.zillowCopy .trending-title {
  font-size: 24px;
  margin-bottom: 0px;
  font-weight: 550;
  color: #333; /* Adjust the color to match Zillow's style */
}

.trending-subtitle {
  color: #818181;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 20px;
  font-weight: 500;
}

.zillowCopy .home-list {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* This creates the gap between the cards */
}

.home-details {
  font-size: 16px; 
  color: #666; 
  margin: 8px 0; 
}

.zillowCopy .home-card {
  flex: 1; /* Each card will take up equal space */
  max-width: 400px; /* Maximum width for each card */
  margin-bottom: 20px; /* Space at the bottom of each card */
  display: flex;
  flex-direction: column; /* Stack image and info on top of each other */
  border-radius: 8px; /* Match the border radius from the screenshot */
  overflow: hidden; /* Keeps everything within the rounded corners */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  background: #fff; /* Background color for the card */
}

.zillowCopy .home-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.zillowCopy .home-card2 {
  flex: 1; /* Each card will take up equal space */
  max-width: 400px; /* Maximum width for each card */
  margin-bottom: 20px; /* Space at the bottom of each card */
  display: flex;
  flex-direction: column; /* Stack image and info on top of each other */
  border-radius: 8px; /* Match the border radius from the screenshot */
  overflow: hidden; /* Keeps everything within the rounded corners */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  background: #fff; /* Background color for the card */
  position: relative;
  overflow: hidden;
}

.zillowCopy .home-image-container {
  height: 200px; /* Fixed height for the image container */
  width: 100%;
  overflow: hidden; /* Ensures images do not overflow their container */
}

.zillowCopy .home-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures images cover the area without distortion */
  transition: transform 0.3s ease-in-out; /* Smooth transition for scaling on hover */
}

.zillowCopy .home-image:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

.zillowCopy .home-info {
  padding: 10px;
  background: #fff; /* Background color for the text info */
}

.zillowCopy .home-address {
  font-size: 16px; /* Adjust size to match Zillow's style */
  color: #494949; /* Text color */
  margin: 5px 0;
}

.zillowCopy .home-price {
  font-size: 20px;
  font-weight: bold;
  color: #0074e4; /* Zillow blue */
  margin: 0; /* No margin to keep the content tight */
}

.zillowCopy .more-homes-button-container {
  text-align: center; /* Centers the button container */
  margin-top: 20px; /* Adds some space above the button */
}

.zillowCopy .more-homes-button {
  display: inline-block; /* Allows the button to shrink to fit content */
  background-color: transparent; /* Button background color */
  color: #0074e4; /* Button text color to match Zillow blue */
  font-weight: bold;
  border: none; /* Removes the border */
  padding: 10px 20px; /* Adds some padding inside the button */
  font-size: 16px; /* Matches the font size from Zillow */
  cursor: pointer; /* Changes the cursor to a pointer on hover */
  position: relative; /* Establishes a positioning context for the pseudo-element */
}

.zillowCopy .more-homes-button::after {
  content: '⬇'; /* The down arrow */
  font-size: 16px; /* Slightly larger size for the arrow */
  position: absolute; /* Positions the pseudo-element relative to the button */
  left: -10px; /* Positions the arrow to the right of the text */
  top: 50%; /* Centers the arrow vertically */
  transform: translateY(-50%); /* Ensures the arrow is perfectly centered */
}

.zillowCopy .more-homes-button:hover {
  text-decoration: underline; /* Underlines the text when hovering */
}

.home-card2 .home-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.home-card2 .explanation-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  color: black;
  font-weight: 450;
  display: flex;
  align-items: flex-start; /* Align items to the start (top) of the container */
  justify-content: center;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* This prevents the overlay from capturing mouse events */
  padding-top: 10px; /* Add padding at the top for some space */
}


.home-card2:hover .home-image {
  opacity: 0.1; /* This will make the image transparent, effectively hiding it */
}

.home-card2:hover .explanation-overlay {
  opacity: 1; /* This will show the explanation overlay */
}




/* selected property for 2nd part specific to house question */
.property-display-wrapper {
  display: flex;
  justify-content: center; /* Center the children (image and info sections) */
  padding: 20px;
  max-width: 1200px; /* Set a max width for the content */
  margin: 0 auto; /* Center the wrapper */
}

.property-image-section {
  flex: none; /* Do not grow or shrink */
  width: 50%; /* 60% of the wrapper width */
  padding: 0; /* No padding needed */
}

.property-info-section {
  flex: none; /* Do not grow or shrink */
  width: 40%; /* 40% of the wrapper width, reduced from 50% */
  padding: 20px; /* Padding inside the info section */
  border: 1px solid #ccc;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
}

.selected-property-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.property-price {
  color: blue;
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 8px; /* Spacing between price and address */
  margin-top: 10px;
}

.property-address {
  font-size: 20px;
  margin-bottom: 16px; /* Spacing between address and bubbles */
  font-weight: 450;
}

.info-bubbles {
  display: flex;
  flex-wrap: wrap; /* Allows bubbles to wrap */
  justify-content: space-between; /* Distributes space between bubbles */
  row-gap: 10px; /* Spacing between rows of bubbles */
  margin-top: 20px;
}

.info-bubble {
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 8px 12px; /* Padding inside the bubbles */
  margin: 5px; /* Margin around the bubbles */
  flex: 1 1 calc(33.333% - 10px); /* Allows bubbles to grow and shrink as needed */
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  /* Ensure that the bubbles can break onto the next line as needed */
  min-width: 150px; /* Minimum width for each bubble */
  font-weight: 450;
}

.school-district {
  font-size: 16px;
  color: #666;
  margin-top: 0px;
  margin-bottom: 30px; /* Space below the school district info, before the separator */
  padding-bottom: 30px; /* Space above the separator line */
  border-bottom: 1px solid #bebebe; /* Thin line below the text */
  display: inline-block; /* Required for the border to fit the content width */
  width: 100%; /* Ensures the line is only as long as the text */
}

.search-container2 {
  top: 30px; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for centering both horizontally and vertically */
  width: auto; /* Adjust based on your desired width, or keep it auto */
  display: flex;
  justify-content: center; /* Center the items (input and button) inside the container */
}
.search-container-underneath {
  margin-top: 50px; /* Add 20px space above the container */
}


.search-container2 .search-input {
  color: black;
  font-weight: 500;
}

.selected-property-page {
  background-color: #f2f2f2;
}

.answers-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Quicksand;
}

.question-answer-card {
  background-color: #f8f8f8; /* Light grey background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-bottom: 20px; /* Space between cards */
  overflow: hidden; /* Ensures the content fits within the corners */
}

.question-section {
  display: flex;
  justify-content: space-between; /* Space out question and arrow */
  align-items: center; /* Align items vertically */
  padding: 16px; /* Padding inside the question section */
  background-color: #e8e8e8; /* Slightly darker background for the question */
  cursor: pointer; /* Change cursor on hover over the question */
}

.question-text {
  margin: 0; /* Reset margins */
  font-weight: bold; /* Bold font for questions */
}

.question-toggle {
  /* Style as needed */
}

.answer-section {
  padding: 16px; /* Padding inside the answer section */
  border-top: 1px solid #d1d1d1; /* Separator between question and answer */
}

.answer-text {
  margin: 0; /* Reset margins */
  font-size: 16px; /* Slightly larger font size for readability */
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000; 
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-top: 4px solid #000;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.back-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-size: 16px;
  color: #333; /* Or any color you want */
  transition: color 0.3s ease;
  font-family: Quicksand;
}

.back-button:hover {
  color: #000; /* Or any hover color you want */
}

.back-button svg {
  margin-right: 8px; /* Space between icon and text */
}

.view-question-history-button {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 25px;
  margin-right: 15px;
}

.history-button {
  text-decoration: none;
  color: #494949;
  font-size: 18px;
}
.history-button:hover {
  text-decoration: underline;
  color: #0c18f7;
}


.toggle-previous-questions-button {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the button content horizontally */
  width: 100%; /* Make the button full width or adjust as needed */
  background-color: transparent;
  border: none; /* Optional: add a border */
  cursor: pointer;
  padding: 10px 15px; /* Adjust padding to your preference */
  font-size: 16px;
  color: #333;
  transition: all 0.3s ease;
  font-family: Quicksand;
  margin-bottom: 20px;
}

.toggle-previous-questions-button:hover {
  color: #000;
  border-color: #000; /* Change border color on hover */
}

.toggle-previous-questions-button svg {
  margin-left: 8px; /* Space between text and icon */
}

.previous-questions {
  padding-bottom: 10px;
}

.log-out-button {
  border: 1px solid #0874f9;
  background: #fff;
  border-radius: 4px;
  padding: 12px; 
  margin-left: 30px;
  margin-right: 20px;
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 16px;
  background-color: #0874f9;
}

.log-out-button:hover {
  background-color: #0c18f7;
}

/* Login.css */
.login-container, .signup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.login-form, .signup-form {
  width: 300px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  margin-top: 20px;
}

.login-input, .signup-input {
  width: calc(100% - 20px);
  margin: 10px auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-button, .signup-button {
  width: 100%;
  margin: 10px auto;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-button:hover, .signup-button:hover {
  background-color: #45a049;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.feedback-message {
  color: green;
  margin-top: 10px;
}

.google-login, .google-signup {
  margin-bottom: 20px;
}

.separator {
  margin: 20px 0;
  font-size: 18px;
  font-weight: bold;
}



.select-user {
  padding: 12px;
  margin-left: 20px;
  font-family: quicksand;
  font-weight: 500;
  font-size: 16px;
}

.history-container {
  padding: 20px;
  padding-top: 0;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Quicksand;
}

.question-history-title {
  font-size: 23px;
}

.history-title {
  font-size: 26px;
}

